import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import install from "./install"

import "./styles/index.sass"
import "./registerServiceWorker"

import "vue-final-modal/style.css"

const app = createApp(App)

app.use(router).use(install)

app.mount("#app")
