import { createVfm } from "vue-final-modal"

export const vfm = createVfm() as any

import { plugin, defaultConfig } from "@formkit/vue"
import config from "./formkit.config"

export default function install(Vue: any) {
  Vue.use(vfm)
  Vue.use(plugin, defaultConfig(config))
}
