import { RouteRecordRaw } from "vue-router"

function redirectToQbeek(to: any) {
  console.log(to.fullPath)
  if (to.fullPath === "/") {
    window.location.href = "https://qbeek.io/"
  }
}
const routes: RouteRecordRaw[] = [
  {
    path: "/:domain",
    name: "outlets",
    component: () => import("./views/List.vue"),
    beforeEnter: [redirectToQbeek],
  },
]

export default routes
