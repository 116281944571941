import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/page/:type',
    name: 'pages',
    component: () => import('./views/Display.vue'),
    props: true,
  },
]

export default routes
