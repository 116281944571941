import { RouteRecordRaw } from "vue-router"

import outletRoutes from "./outlet/router"
import catalogRoutes from "./catalog/router"
import pagesRoutes from "./pages/router"
import promoRoutes from "./promo/router"

const routes: Array<RouteRecordRaw> = [
  ...promoRoutes,
  ...outletRoutes,
  ...catalogRoutes,
  ...pagesRoutes,
]

export default routes
