import { RouteRecordRaw } from "vue-router"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:domain/:subdomain/",
    name: "catalog",
    component: () => import("./views/List.vue"),
  },
]

export default routes
