import { ru } from "@formkit/i18n"
import { DefaultConfigOptions } from "@formkit/vue"
import {
  createMultiStepPlugin,
  createLocalStoragePlugin,
} from "@formkit/addons"
import "@formkit/addons/css/multistep"

const config: DefaultConfigOptions = {
  locales: { ru },
  locale: "ru",
  theme: "genesis",
  plugins: [
    createMultiStepPlugin(),
    createLocalStoragePlugin({
      // plugin defaults:
      prefix: "formkit",
      key: undefined,
      control: undefined,
      maxAge: 3600000, // 1 hour
      debounce: 200,
      beforeSave: undefined,
      beforeLoad: undefined,
    }),
  ],
}

export default config
