export enum AppLayoutsEnum {
  default = "default",
  promo = "promo",
}

export const AppLayoutToFileMap: Record<AppLayoutsEnum, string> = {
  default: "Default.vue",
  promo: "PromoLayouts.vue",
}

