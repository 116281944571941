import { AppLayoutsEnum } from '@/layouts/layouts.types'
import { RouteRecordRaw } from "vue-router"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/promo/:merchantId",
    name: "promo",
    component: () => import("./views/Promo.vue"),
    meta: {
      layout: AppLayoutsEnum.promo,
    }
  },
]

export default routes
